export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorIdDDV = id_ddv => {
  /* eslint-disable no-useless-escape */
  const regExp = /^[0-9]{8}$/
  /* eslint-enable no-useless-escape */
  const validIdDDV = regExp.test(id_ddv)
  return validIdDDV
}

export const validatorPhoneNumber = phoneNumber => {
  /* eslint-disable no-useless-escape */
  //Remove spaces
  phoneNumber = phoneNumber.replace(/\s/g, '')

  //With prefix +386
  const regFirst = /^\+(386)([1-7][0-9]{7}|([347]0|[3457]1|6[4589]){6})$/
  //Normal 051 XXX XXX
  const regSecond = /^0([1-7][0-9]{7}|([347]0|[3457]1|6[4589]){6})$/
  //With prefix 00386
  const regThird = /^00386([1-7][0-9]{7}|([347]0|[3457]1|6[4589]){6})$/


  /* eslint-enable no-useless-escape */
  const validatePhoneNumber = regFirst.test(phoneNumber) | regSecond.test(phoneNumber) | regThird.test(phoneNumber)
  return validatePhoneNumber
}


export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

export const validatorAtLeastOneUppercase = val => {
  const re = /[A-Z]/g

  return re.test(val)
}

export const validatorAtLeastOneDigit = val => {

  const re = /[0-9]/g

  return re.test(val)
}
